<template>
  <div>
    <topnavigation class="header"></topnavigation>
    <router-view></router-view>
    <bottombanner></bottombanner>
  </div>
</template>
<script>
import topnavigation from "../../components/topnavigation.vue";
import bottombanner from "../../components/bottombanner.vue";
export default {
  components: { topnavigation, bottombanner },
};
</script>
<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
